import { Dispatch } from 'redux';

import ApiClient from './ApiClient';
import { loginUser, logoutUser } from '../actions';

class UserAuthenticator {
  private dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  login(
    email: string,
    password: string,
    failureCallback?: (err?: string) => void,
  ) {
    ApiClient.login(email, password)
      .then(() => {
        this.dispatch(loginUser());
      })
      .catch((err) => {
        if (failureCallback) {
          failureCallback(err);
        }
      });
  }

  logout(callback?: () => void) {
    ApiClient.get('/api/logout').then(() => {
      if (callback) {
        callback();
      }

      this.dispatch(logoutUser());
    });
  }
}

export default UserAuthenticator;
