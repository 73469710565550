import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Theme, Typography } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 100,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  centered: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
}));

type PageContainerProps = {
  children: ReactNode,
  actions?: ReactNode,
  centered?: boolean,
  title?: string,
};

const PageContainer = (props: PageContainerProps) => {
  const classes = useStyles();
  const { children, actions, title, centered } = props;

  return (
    <Container className={`${classes.root} ${centered && classes.centered}`}>
      <Breadcrumbs />
      <Box className={classes.header}>
        {title && <Typography variant="h1">{title}</Typography>}
        {actions}
      </Box>
      {children}
    </Container>
  );
};

export default PageContainer;
