import React from 'react';
import { matchPath } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { User } from '../types';
import PlatformOverview from '../modules/platform/PlatformOverview';
import Login from '../Login';
import NotFound from '../components/NotFound';

// TODO: Extract to own directory and seperate files for each class.
class Router {
  routes: RouteNode[] = [];

  notFoundComponent: JSX.Element | null = null;

  addRoute(node: RouteNode) {
    this.routes.push(node);
  }

  getNodeByPath(path: string) {
    let match = null;

    this.getRoutes().forEach((route) => {
      if (matchPath(path, { path: route.getPath(), exact: true, strict: false })) {
        match = route;
      }
    });

    return match;
  }

  getSwitch(user?: User | null) {
    const routes = this.getRoutes()
      .filter((route) => {
        if (route.isAnonymous) {
          return !user;
        }
        return !route.isRequiringLogin || (route.isRequiringLogin && user);
      })
      .map((route) => (
        <Route exact path={route.getPath()}>
          {route.getElement()}
        </Route>
      ));

    return (
      <Switch>
        { routes }
        { this.notFoundComponent }
      </Switch>
    );
  }

  setNotFoundComponent(element: JSX.Element) {
    this.notFoundComponent = element;
    return this;
  }

  private getRoutes() {
    let routes: RouteNode[] = [];

    this.routes.forEach((route) => {
      routes = routes.concat(this.getChildrenOfNode(route));
    });

    return routes;
  }

  private getChildrenOfNode(node: RouteNode, result: RouteNode[] = []) {
    result.push(node);
    const children = node.getChildren();
    if (children.length > 0) {
      children.forEach((child) => this.getChildrenOfNode(child, result));
    }

    return result;
  }
}

// TODO: Rename RouteNode to simply Route
export class RouteNode {
  private readonly title: string;

  private readonly path: string;

  private readonly element: JSX.Element;

  private parent: RouteNode | null = null;

  private children: RouteNode[] = [];

  isRequiringLogin: boolean = false;

  isAnonymous: boolean = false;

  constructor(title: string, path: string, element: JSX.Element) {
    this.title = title;
    this.path = path;
    this.element = element;
  }

  requireLogin() {
    this.isRequiringLogin = true;
    return this;
  }

  anonymous() {
    this.isAnonymous = true;
    return this;
  }

  getTitle() {
    return this.title;
  }

  getPath() {
    let { path } = this;

    if (this.parent) {
      path = this.parent.getPath() + path;
    }

    return path;
  }

  getElement() {
    return this.element;
  }

  getChildren() {
    return this.children;
  }

  getParent() {
    return this.parent;
  }

  setParent(node: RouteNode) {
    this.parent = node;
  }

  addChild(node: RouteNode) {
    node.setParent(this);
    this.children.push(node);
  }
}

const router = new Router();

// TODO: Set these in the App
router.setNotFoundComponent(<NotFound />);
// TODO: Set the login page as well, so unauthorized users get login instead of 404.
router.addRoute(new RouteNode('Login', '/', <Login />).anonymous());
router.addRoute(new RouteNode('Home', '/', <PlatformOverview />).requireLogin());

export default router;
