import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { green, grey, orange, red } from '@material-ui/core/colors';
import { Platform } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    display: 'inline-block',
    width: 11,
    height: 11,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    transform: 'translateY(1px)',
  },
  good: {
    backgroundColor: green[500],
  },
  warning: {
    backgroundColor: orange[500],
  },
  bad: {
    backgroundColor: red[500],
  },
  unknown: {
    backgroundColor: grey[500],
  },
}));

interface PlatformStatusProps {
  platform: Platform;
}

const PlatformStatus = (props: PlatformStatusProps) => {
  const classes = useStyles();
  const { lastCommitDate, syncFailed } = props.platform;

  if (lastCommitDate === undefined) {
    return <div className={`${classes.badge} ${classes.unknown}`} />;
  }

  const difference = moment().diff(moment(lastCommitDate), 'days');

  let statusClass;

  if (syncFailed) {
    statusClass = classes.bad;
  } else if (difference <= 30) {
    statusClass = classes.good;
  } else if (difference > 30 && difference <= 60) {
    statusClass = classes.warning;
  } else {
    statusClass = classes.bad;
  }

  const className = `${classes.badge} ${statusClass}`;

  return (
    <div className={className} />
  );
};

export default PlatformStatus;
