import React from 'react';
import router, { RouteNode } from '../../config/router';
import PlatformOverview from './PlatformOverview';
import CreatePlatform from './CreatePlatform';
import EditPlatform from './EditPlatform';
import ViewPlatform from './ViewPlatform';

const platforms = new RouteNode('Platforms', '/platforms', <PlatformOverview />);
platforms.addChild(new RouteNode('Create platform', '/create', <CreatePlatform />));
platforms.addChild(new RouteNode('View platform', '/:id', <ViewPlatform />));
platforms.addChild(new RouteNode('Edit platform', '/:id/edit', <EditPlatform />));
router.addRoute(platforms);
