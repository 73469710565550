import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Paper,
  Table, TableCell,
  TableContainer, TableHead,
  TextField,
  Theme,
  TableRow,
  TableBody, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import PageContainer from '../../components/PageContainer';
import PlatformRepository from './PlatformRepository';
import UserAutocomplete from '../users/UserAutocomplete';
import { MinimalUser, Platform, PlatformModule } from '../../types';
import Loader from '../../components/Loader';
import StatusIndicator from '../../components/StatusIndicator';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 450,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const ViewPlatform = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [platform, setPlaform] = useState<Platform|null>(null);

  useEffect(() => {
    PlatformRepository.find(id)
      .then((response) => {
        setPlaform(response.data);
      })
      .catch(() => {
        history.push('/platforms');
      });
  }, []);

  const formatModuleName = (name: string) => name.split('-').map((p) => capitalize(p)).join(' ');

  if (!platform) {
    return <Loader />;
  }

  const { name, modules, features } = platform;

  const getFeaturesByModule = (module: string) => {
    if (!features) {
      return [];
    }

    return features.filter(({ feature }) => feature.module.name === module);
  };

  return (
    <div>
      <PageContainer title={name}>
        <Box pt={3} pb={3}>
          { modules && Object.entries(modules).map(([key, value]) => ({ name: key, enabled: value }))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ name, enabled }) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon size="sm" icon={['fal', 'chevron-down']} />}
                  disabled={!enabled}
                >
                  <Box display="flex" flexDirection="row">
                    <Box mr={1}><StatusIndicator indicator={enabled ? 'green' : 'red'} /></Box>
                    {formatModuleName(name)}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Feature</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      {getFeaturesByModule(name).map(({ feature, enabled, roles }) => (
                        <TableRow>
                          <TableCell>{feature.name}</TableCell>
                          <TableCell>
                            { enabled !== null && <StatusIndicator indicator={enabled ? 'green' : 'red'} /> }
                            { roles && roles.join(', ') }
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      </PageContainer>
    </div>
  );
};

export default ViewPlatform;
