import React from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper = (props: ConditionalWrapperProps) => {
  const { condition, wrapper, children } = props;

  if (condition) {
    return wrapper(children);
  }

  return children;
};

export default ConditionalWrapper;
