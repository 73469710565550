import { User } from '../types';

export const loginUser = () => ({
  type: 'LOGIN_USER',
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
});

export const setAccount = (user: User) => ({
  type: 'SET_ACCOUNT',
  payload: user,
});

export const setImitating = (status: boolean) => ({
  type: 'SET_IMITATING',
  payload: status,
});
