import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';

import ApiClient from '../../api/ApiClient';
import { setAccount, setImitating } from '../../actions';
import { getTimestamp } from '../../utils/date';
import { User } from '../../types';

class UserImitator {
  /**
   * Dispatcher for dispatching Redux actions.
   */
  readonly dispatch: Dispatch<any>;

  constructor(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  /**
   * Check if user is imitating.
   */
  isImitating(): boolean {
    const imitating = localStorage.getItem('imitating');
    const expiresAt = localStorage.getItem('imitation-expires-at');

    if (!imitating || !expiresAt) {
      return false;
    }

    if (getTimestamp() > parseInt(expiresAt, 10)) {
      localStorage.removeItem('imitating');
      localStorage.removeItem('imitation-expires-at');
      return false;
    }

    this.dispatch(setImitating(true));

    return true;
  }

  /**
   * Call API to set IMITATE_BEARER cookie.
   * Update the Local Storage such that the imitation state is persisted.
   *
   * @param userId
   */
  async imitate(userId: string) {
    if (this.isImitating()) {
      await this.stopImitating(() => this.imitate(userId));
      return;
    }
    // This will set the IMITATE_BEARER cookie to a JWT associated with the imitated user.
    await ApiClient.get(`/api/users/${userId}/imitate`);

    this.dispatch(setImitating(true));
    localStorage.setItem('imitating', '1');

    // The cookie remains valid for 1 week.
    const weekInSeconds = 604800;
    localStorage.setItem(
      'imitation-expires-at',
      (getTimestamp() + weekInSeconds).toString(),
    );
    await this.refreshAccount(() => {});
  }

  /**
   * Remove the IMITATE_BEARER cookie in the API.
   * Set imitating to false and store state in Local Storage.
   */
  async stopImitating(callback?: () => void) {
    // This will remove the IMITATE_BEARER cookie.
    await ApiClient.get('/api/stop-imitation');
    await this.refreshAccount(() => {
      if (callback) {
        callback();
      }

      localStorage.removeItem('imitating');
      this.dispatch(setImitating(false));
    });
  }

  /**
   * Refresh the user account.
   */
  async refreshAccount(callback?: () => void) {
    const account: AxiosResponse<User> = await ApiClient.get(
      '/api/users/account',
    );
    this.dispatch(setAccount(account.data));

    if (callback) {
      callback();
    }
  }
}

export default UserImitator;
