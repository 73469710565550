import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageContainer from '../../components/PageContainer';
import { TimeEstimationTask } from '../../types';

type TaskKey = keyof TimeEstimationTask;

interface Stats {
  sum: number;
  avgVariance: number;
  lowerBound95: number;
  upperBound95: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  addTaskButton: {
    margin: theme.spacing(2),
  },
  numberColumn: {
    width: 80,
  },
  resultRow: {
    background: grey[100],
  },
  resultLabelColumn: {
    width: 250,
  },
  optionsColumn: {
    width: 50,
  },
  iconButton: {
    width: 28,
    height: 28,
  },
}));

const TimeEstimator = () => {
  const classes = useStyles();
  const [tasks, setTasks] = useState<TimeEstimationTask[]>([]);
  const [stats, setStats] = useState<Stats>({
    sum: 0,
    avgVariance: 0,
    lowerBound95: 0,
    upperBound95: 0,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    if (e.target.name !== 'description') {
      (tasks[index][e.target.name as TaskKey] as any) = parseInt(e.target.value, 10);
    } else {
      (tasks[index][e.target.name as TaskKey] as any) = value;
    }

    setTasks([...tasks]);
  };

  const handleRemoveTask = (index: number) => {
    tasks.splice(index, 1);
    setTasks([...tasks]);
  };

  const handleSave = () => {
    console.log({
      estimation: {
        title: 'Test',
        tasks,
      },
    });
  };

  const addTask = () => {
    setTasks([...tasks, {
      description: '',
      optimistic: 0,
      likely: 0,
      pessimistic: 0,
    }]);
  };

  const getTaskMean = (task: TimeEstimationTask) => (task.optimistic + task.likely + task.pessimistic) / 3;

  const getTaskVariance = (task: TimeEstimationTask) => {
    const mean = getTaskMean(task);
    return ((task.optimistic - mean) ** 2 + (task.likely - mean) ** 2 + (task.pessimistic - mean) ** 2) / 3;
  };

  useEffect(() => {
    addTask();
  }, []);

  useEffect(() => {
    const sum = tasks.reduce((value, task) => value + getTaskMean(task), 0);
    const avgVariance = tasks.reduce((value, task) => value + getTaskVariance(task), 0) / tasks.length;

    const lowerBound95 = Math.max(sum - 1.96 * Math.sqrt(avgVariance), 0);
    const upperBound95 = sum + 1.96 * Math.sqrt(avgVariance);

    setStats({ sum, avgVariance, lowerBound95, upperBound95 });
  }, [tasks]);

  return (
    <PageContainer title="Time Estimation">
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Optimistic</TableCell>
              <TableCell>Probable</TableCell>
              <TableCell>Pessimistic</TableCell>
              <TableCell>&mu;</TableCell>
              <TableCell>&sigma;</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow>
                <TableCell>
                  <TextField
                    name="description"
                    value={task.description}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                    fullWidth
                  />
                </TableCell>
                <TableCell className={classes.numberColumn}>
                  <TextField
                    name="optimistic"
                    value={task.optimistic}
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                  />
                </TableCell>
                <TableCell className={classes.numberColumn}>
                  <TextField
                    name="likely"
                    value={task.likely}
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                  />
                </TableCell>
                <TableCell className={classes.numberColumn}>
                  <TextField
                    name="pessimistic"
                    value={task.pessimistic}
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                  />
                </TableCell>
                <TableCell className={classes.resultLabelColumn}>{getTaskMean(task).toFixed(1)}</TableCell>
                <TableCell className={classes.numberColumn}>{Math.sqrt(getTaskVariance(task)).toFixed(1)}</TableCell>
                <TableCell className={classes.optionsColumn}>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveTask(index)}
                    className={classes.iconButton}
                  >
                    <FontAwesomeIcon icon={['fal', 'times']} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow className={classes.resultRow}>
              <TableCell rowSpan={3} colSpan={4} />
              <TableCell>
                <strong>Total:</strong>
              </TableCell>
              <TableCell>
                {stats.sum.toFixed(1)}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow className={classes.resultRow}>
              <TableCell>
                <strong>&sigma;:</strong>
              </TableCell>
              <TableCell>
                {Math.sqrt(stats.avgVariance).toFixed(1)}
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow className={classes.resultRow}>
              <TableCell>
                <strong>Estimate (95% confidence interval):</strong>
              </TableCell>
              <TableCell>
                { stats.lowerBound95.toFixed() }
                {' '}
                -
                { stats.upperBound95.toFixed() }
              </TableCell>
              <TableCell />
            </TableRow>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={addTask}
              className={classes.addTaskButton}
              startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
            >
              Add task
            </Button>
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
};

export default TimeEstimator;
