/**
 * Parses API filter criteria to a params object.
 *
 * @param criteria
 * @param params
 */
import { ApiFilterCriteria } from '../types';

export function parseCriteria(
  criteria: ApiFilterCriteria | undefined,
  params: {
    [key: string]: string | number | null | boolean;
  },
) {
  if (!criteria) {
    return params;
  }

  const result: {
    [key: string]: string | number | null | boolean;
  } = {
    ...params,
    query: criteria.query || null,
  };

  if (criteria.filters) {
    Object.entries(criteria.filters).forEach((filter) => {
      result[filter[0]] = filter[1];
    });
  }

  if (criteria.order) {
    criteria.order.forEach((item) => {
      result[`order[${item.field}]`] = item.order;
    });
  }

  return result;
}
