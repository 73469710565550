import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { CommitResponse, TokenResponse } from '../types';

class ApiClient {
  client: AxiosInstance;

  url: string | null;

  constructor() {
    this.client = axios.create({
      withCredentials: true,
    });
    this.url = process.env.REACT_APP_API_URL || null;
  }

  login(email: string, password: string): Promise<AxiosResponse> {
    return this.client.post(`${this.url}/api/login`, { email, password });
  }

  getRoles() {
    return this.get('/api/roles');
  }

  getToken(email: string, password: string): Promise<TokenResponse> {
    return this.client.post(`${this.url}/api/token`, { email, password });
  }

  getTokenWithRefreshToken(refreshToken: string): Promise<TokenResponse> {
    return this.client.post(`${this.url}/api/token/refresh`, { refresh_token: refreshToken });
  }

  getLatestCommit(): Promise<CommitResponse> {
    return this.get('/api/repository/commits/last');
  }

  getCommit(sha: string): Promise<CommitResponse> {
    return this.get(`/api/repository/commits/${sha}`);
  }

  async get(uri: string, params = {}) {
    return this.client.get(this.url + uri, { params });
  }

  async put(uri: string, data = {}) {
    return this.client.put(this.url + uri, data);
  }

  async post(uri: string, data = {}) {
    return this.client.post(this.url + uri, data);
  }

  async delete(uri: string) {
    return this.client.delete(this.url + uri);
  }
}

export default new ApiClient();
