import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { SnackbarProvider } from 'notistack';

import App from './App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import { loginUser } from './actions';
import UserRepository from './modules/users/UserRepository';
import UserImitator from './modules/users/UserImitator';

const store = createStore(rootReducer);

// Attempt to get account from API.
new UserRepository()
  .getAccount()
  .then(() => {
    // If succesful, login the user.
    store.dispatch(loginUser());

    new UserImitator(store.dispatch).isImitating();
  })
  .catch(() => {})
  .finally(() => {
    // Render the DOM once login check is finished.
    ReactDOM.render(
      <Provider store={store}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} maxSnack={3} autoHideDuration={3500}>
          <App />
        </SnackbarProvider>
      </Provider>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
