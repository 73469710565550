import { AxiosResponse } from 'axios';
import ApiClient from '../../api/ApiClient';
import { ApiFilterCriteria, HydraCollectionResponse, Repository, User } from '../../types';
import { parseCriteria } from '../../utils/common';

class UserRepository implements Repository<User> {
  find(id: string): Promise<AxiosResponse<User>> {
    return ApiClient.get(`/api/users/${id}`);
  }

  findBy(criteria?: ApiFilterCriteria, page: number = 1, perPage: number = 10): Promise<HydraCollectionResponse<User>> {
    const params = parseCriteria(criteria, { page, itemsPerPage: perPage });
    return ApiClient.get('/api/users', params);
  }

  getAccount(): Promise<AxiosResponse<User>> {
    return ApiClient.get('/api/users/account');
  }

  update(user: User) {
    return ApiClient.put(`/api/users/${user.id}`, user);
  }

  create(user: User) {
    return ApiClient.post('/api/users', user);
  }

  delete(id: string) {
    return ApiClient.delete(`/api/users/${id}`);
  }
}

export default UserRepository;
