import { library } from '@fortawesome/fontawesome-svg-core';
import { faBrowser, faSave, faUsers, faHourglass, faAtom, faMobile } from '@fortawesome/pro-duotone-svg-icons';
import {
  faBars, faPlus, faEdit, faTrash, faSync,
  faSearch, faTimes, faEye, faEyeSlash, faChevronDown,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faBars, faBrowser, faPlus, faSave, faEdit, faTrash, faSync,
  faSearch, faUsers, faHourglass, faTimes, faEye, faEyeSlash,
  faAtom, faChevronDown, faMobile,
);
