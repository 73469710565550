import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import PageContainer from '../../components/PageContainer';
import UserRepository from './UserRepository';
import { User } from '../../types';
import UserForm from './UserForm';

const EditUser = () => {
  const repository = new UserRepository();
  const notifications = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    repository.find(id)
      .then((res) => setUser(res.data))
      .catch(() => history.push('/users'));
  }, []);

  const submit = (e: FormEvent) => {
    e.preventDefault();
    if (!user) {
      return;
    }
    repository.update(user)
      .then(() => {
        history.push('/users');
        notifications.enqueueSnackbar('User updated succesfully!', { variant: 'success' });
      })
      .catch(() => notifications.enqueueSnackbar('Failed to update user!', { variant: 'error' }));
  };

  return (
    <div>
      <PageContainer title="Edit user">
        { user && <UserForm submit={submit} user={user} /> }
      </PageContainer>
    </div>
  );
};

export default EditUser;
