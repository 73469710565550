import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/styles';

import './App.scss';
import './config/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import theme, { colors } from './config/theme';

import Navigation from './Navigation';
import router from './config/router';
import { User } from './types';

// Modules
import './modules/modules';
import './modules/platform';
import './modules/time-estimation';
import './modules/users';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: colors.grey.light,
    paddingLeft: 250,
    width: '100%',
    minHeight: '100vh',
  },
}));

function App(props: { isLoggedIn: boolean }) {
  const classes = useStyles();
  const { isLoggedIn } = props;

  // Refactor: Get account data first...
  let user: User | null = null;
  if (isLoggedIn) {
    user = {
      email: 'test',
      roles: [],
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        { isLoggedIn && (
          <Navigation />
        )}
        <div className={classes.wrapper}>
          { router.getSwitch(user) }
        </div>
      </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: {user: { isLoggedIn: boolean }}) => ({
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(App);
