import React, { useEffect, useState } from 'react';
import { Box, capitalize } from '@material-ui/core';
import { Barplot } from '@melvinkoopmans/d3plotlib';
import PageContainer from '../../components/PageContainer';
import ModuleRepository from './ModuleRepository';
import Loader from '../../components/Loader';
import { ModuleStatistics } from '../../types';

const ModuleOverview = () => {
  const moduleRepository = new ModuleRepository();
  const [stats, setStats] = useState<ModuleStatistics|null>(null);

  useEffect(() => {
    moduleRepository.getModuleStatistics()
      .then((response) => setStats(response.data));
  }, []);

  useEffect(() => {
    if (!stats) {
      return;
    }

    new Barplot('#module-usage-stats', 500, 1600)
      .yLabel('Frequency')
      .xLabel('Module')
      .horizontal()
      .tooltip()
      .colors(['#007bff', '#0032a3'])
      .plot(stats.usageCount.map(({ name, count }) => ({
        name: name.split('-').map((p) => capitalize(p)).join(' '),
        count,
      })), 'name', 'count');
  });

  if (stats === null) {
    return <Loader />;
  }

  return (
    <PageContainer title="Modules">
      <Box mt={3} pb={3}>
        <div id="module-usage-stats" style={{ position: 'relative' }} />
      </Box>
    </PageContainer>
  );
};

export default ModuleOverview;
