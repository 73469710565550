import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Breadcrumbs as BreadcrumbsUI, Link as LinkUI, Theme, Typography } from '@material-ui/core';

import router from '../config/router';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
}));

const Breadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();

  const createCrumbsFromNode = (node: any, result: any[] = []) => {
    result.push(node);
    const parent = node.getParent();
    if (parent) {
      createCrumbsFromNode(parent, result);
    }

    return result.map((node, i) => {
      if (i === 0) {
        return <Typography color="textPrimary">{node.getTitle()}</Typography>;
      }

      return (
        <LinkUI component={Link} to={node.getPath()}>{node.getTitle()}</LinkUI>
      );
    }).reverse();
  };

  const currentNode = router.getNodeByPath(location.pathname);
  if (!currentNode) {
    return <div />;
  }

  const crumbs = createCrumbsFromNode(currentNode);

  if (crumbs.length <= 1) {
    return <div />;
  }

  return (
    <BreadcrumbsUI className={classes.container} aria-label="breadcrumb">
      { crumbs }
    </BreadcrumbsUI>
  );
};

export default Breadcrumbs;
