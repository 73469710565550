import React from 'react';
import {
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';

import DataTable from '../../components/DataTable';
import UserRepository from './UserRepository';

const UsersOverview = () => {
  const Actions = () => (
    <Link to="/users/create">
      <Button color="primary" startIcon={<FontAwesomeIcon icon={['fal', 'plus']}/>}>
        Create user
      </Button>
    </Link>
  );

  const columns = [
    {
      name: 'ID',
      field: 'id',
      sortable: false,
    },
    {
      name: 'E-mail',
      field: 'email',
      sortable: true,
    },
  ];

  const itemActions = (id: string, className: string) => (
    <div>
      <IconButton size="small" component={Link} to={`/users/${id}/edit`} className={className}>
        <FontAwesomeIcon icon={['fal', 'edit']} />
      </IconButton>
    </div>
  );

  return (
    <PageContainer title="Users" actions={<Actions />}>
      <Box mt={3}>
        <DataTable repository={new UserRepository()} columns={columns} actions={itemActions} searchable deletable/>
      </Box>
    </PageContainer>
  );
};

export default UsersOverview;
