import ApiClient from '../../api/ApiClient';
import { ApiFilterCriteria, MinimalUser, Platform, PlatformResponse, PlatformsResponse, Repository } from '../../types';
import { parseCriteria } from '../../utils/common';

type PlatformData = {
    name: string,
    url: string,
    owners: string[],
    mobileApp: boolean,
    privateKey?: string | null,
}

class PlatformRepository implements Repository<Platform> {
  public static create(name: string, url: string, privateKey: string, owners: MinimalUser[] = [], mobileApp: boolean) {
    return ApiClient.post('/api/platforms', { name, url, privateKey, owners: owners.map((u) => u.id), mobileApp });
  }

  findBy(criteria?: ApiFilterCriteria, page: number = 1): Promise<PlatformsResponse> {
    const params = parseCriteria(criteria, { page, itemsPerPage: 1000 });

    if (params.query) {
      params.name = params.query;
    }

    return ApiClient.get('/api/platforms', params);
  }

  public static find(id: string): Promise<PlatformResponse> {
    return ApiClient.get(`/api/platforms/${id}`);
  }

  public static update(id: string, { name, url, owners, mobileApp, privateKey }: PlatformData) {
    const data: PlatformData = { name, url, owners, mobileApp };

    if (privateKey !== null) {
      data.privateKey = privateKey;
    }

    return ApiClient.post(`/api/platforms/${id}`, data);
  }

  delete(id: string) {
    return ApiClient.delete(`/api/platforms/${id}`);
  }

  sync(id: string) {
    return ApiClient.get(`/api/sync/${id}`);
  }

  syncAll() {
    return ApiClient.get('/api/sync/all');
  }
}

export default PlatformRepository;
