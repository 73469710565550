import { AxiosResponse } from 'axios';
import ApiClient from '../../api/ApiClient';
import { ModuleStatistics } from '../../types';

class ModuleRepository {
  getModuleStatistics(): Promise<AxiosResponse<ModuleStatistics>> {
    return ApiClient.get('/api/modules/statistics');
  }
}

export default ModuleRepository;
