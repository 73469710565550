import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import { ApiFilterCriteria, MinimalUser } from '../../types';
import UserRepository from './UserRepository';

interface UserAutocompleteProps {
  initialUsers?: MinimalUser[];
  onChange: (users: MinimalUser[]) => void;
  error?: boolean;
}

const UserAutocomplete = (props: UserAutocompleteProps) => {
  const { initialUsers, onChange, error } = props;
  const [value, setValue] = useState<MinimalUser[]>(initialUsers || []);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<MinimalUser[]>(initialUsers || []);
  const userRepository = new UserRepository();

  const getOptions = debounce((query: string) => {
    const criteria: ApiFilterCriteria = { query, filters: {} };

    userRepository.findBy(criteria, 1, 10000).then((response) => {
      setOptions(response.data['hydra:member'] as MinimalUser[]);
    });
  }, 200);

  useEffect(() => {
    if (inputValue.length >= 3) {
      getOptions(inputValue);
    }
  }, [inputValue, value]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          label="Zoek een gebruiker..."
          variant="outlined"
          error={error}
          fullWidth
        />
      )}
      getOptionLabel={(option) => option.email || ''}
      options={options}
      autoComplete
      onChange={(event: any, newValue: MinimalUser[]) => {
        setOptions(newValue ? [...newValue, ...options] : options);
        setValue(newValue);
      }}
      value={value}
      noOptionsText="Geen gebruiker(s) gevonden"
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderOption={(option: MinimalUser) => <div>{option.email}</div>}
      multiple
    />
  );
};

export default UserAutocomplete;
