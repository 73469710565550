import React from 'react';
import PageContainer from './PageContainer';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
  },
  title: {
    display: 'block',
    width: '100%',
  },
  text: {
    fontWeight: 300,
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <PageContainer centered>
      <Box className={classes.container} mt={5}>
        <h1 className={classes.title}>Oops!</h1>
        <h3 className={classes.text}>We can't seem to find the page<br /> you're looking for.</h3>
      </Box>
    </PageContainer>
  );
};

export default NotFound;
