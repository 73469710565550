import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Container, TextField, Theme, Typography } from '@material-ui/core';
import validator from 'validator';
import { makeStyles } from '@material-ui/styles';
import UserAuthenticator from './api/UserAuthenticator';
import PasswordInput from './components/password/PasswordInput';
import { ReactComponent as Logo } from './assets/logo_yunits.svg';
import { ReactComponent as Illustration } from './assets/laptop_chart.svg';
import { ReactComponent as WaveLines } from './assets/wave_lines.svg';
import { colors } from './config/theme';

type LoginState = {
  email: string;
  password: string;
  errors: { email?: boolean | undefined; password?: boolean | undefined };
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    background: colors.grey.light,
  },
  container: {
    paddingTop: 150,
  },
  logo: {
    position: 'relative',
    height: 55,
    width: 'auto',
    marginBottom: theme.spacing(6),
  },
  illustration: {
    position: 'absolute',
    top: 30,
    right: 340,
    width: 370,
  },
  diagonal: {
    position: 'absolute',
    zIndex: 0,
    width: '100vw',
    height: '100vh',
    right: '50%',
    transform: 'skew(15deg)',
    background: '#FFF',
  },
  waveLines: {
    position: 'absolute',
    top: 410,
    height: 300,
  },
  formWrapper: {
    position: 'relative',
    display: 'inline-block',
    zIndex: 1,
    overflow: 'hidden',
    borderRadius: 4,
    padding: 45,
    paddingBottom: 50,
    boxShadow: '2px 2px 24px 1px rgba(0, 0, 0, 0.05 )',
    background: '#FFF',
  },
  form: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    '& .MuiInputBase-root': {
      background: colors.grey.light,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: colors.grey.light,
    },
  },
  formTitle: {
    fontWeight: 600,
    fontSize: 25,
  },
  field: {
    width: 250,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  leadText: {
    position: 'relative',
    zIndex: 1,
    fontSize: theme.typography.h4.fontSize,
    marginBottom: 70,
  },
  dash: {
    width: 45,
    height: 6,
    background: '#007BFF',
    borderRadius: 8,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState<LoginState>({
    email: '',
    password: '',
    errors: {},
  });
  const { email, password, errors } = state;

  const handleFailure = () => {
    errors.email = true;
    errors.password = true;

    setState({
      ...state,
      errors,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    errors.email = !validator.isEmail(email);
    errors.password = validator.isEmpty(password);

    setState({ ...state, errors });

    if (!validator.isEmail(email || '') || validator.isEmpty(password || '')) {
      return;
    }

    new UserAuthenticator(dispatch).login(
      email,
      password,
      handleFailure,
    );
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: e.target.value });
  };

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, password: e.target.value });
  };

  useEffect(() => {
    document.body.classList.add('login-page');

    return () => document.body.classList.remove('login-page');
  });

  return (
    <div className={classes.root}>
      <div className={classes.diagonal} />
      <Illustration className={classes.illustration} />
      <WaveLines className={classes.waveLines} />
      <Container className={classes.container}>
        <Logo className={classes.logo} />
        <Typography variant="body1" className={classes.leadText}>
          More impact with your
          <br />
          online community platform.
        </Typography>

        <Box className={classes.formWrapper}>
          <Typography variant="h1" className={classes.formTitle}>
            Inloggen
          </Typography>
          <Box my={2} mb={1}>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque laoreet mollis.
            </Typography>
          </Box>
          <div className={classes.dash} />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box mr={4}>
              <TextField
                type="email"
                data-testid="email"
                className={classes.field}
                value={email || ''}
                onChange={changeEmail}
                error={errors.email}
                label="Gebruikersnaam"
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box mr={4}>
              <PasswordInput
                id="password"
                data-testid="password"
                className={classes.field}
                onChange={changePassword}
                error={errors.password}
                outlined
              />
            </Box>
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                data-testid="submit"
                size="large"
              >
                Inloggen
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
