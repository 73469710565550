import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  grey: {
    light: '#f5f6fa',
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#007BFF',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff',
    },
    secondary: {
      main: '#00FFC2',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
  },

  typography: {
    body1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 35,
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 20,
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

export default theme;
