import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

interface PasswordInputProps {
  id: string;
  error?: boolean | undefined;
  value?: string | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  outlined?: boolean;
  readOnly?: boolean;
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

const PasswordInput = (props: PasswordInputProps) => {
  const classes = useStyles();
  const { id, value, error, onChange, label, className, outlined, readOnly } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleToggle = () => setShowPassword(!showPassword);

  return outlined ? (
    <FormControl
      variant="outlined"
      className={`${className} ${classes.formControl}`}
    >
      <TextField
        id={id}
        data-testid={id}
        onChange={onChange}
        variant="outlined"
        label={label || 'Wachtwoord'}
        type={showPassword ? 'text' : 'password'}
        error={error}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggle}
              >
                <FontAwesomeIcon
                  icon={['fal', showPassword ? 'eye-slash' : 'eye']}
                  fixedWidth
                />
              </IconButton>
            </InputAdornment>
          ),
          readOnly,
        }}
        fullWidth
      />
    </FormControl>
  ) : (
    <FormControl className={`${className} ${classes.formControl}`}>
      <InputLabel htmlFor={id}>{label || 'Wachtwoord'}</InputLabel>
      <Input
        id={id}
        data-testid={id}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        error={error}
        value={value}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleToggle}
            >
              <FontAwesomeIcon
                icon={['fal', showPassword ? 'eye-slash' : 'eye']}
                fixedWidth
              />
            </IconButton>
          </InputAdornment>
        )}
        inputProps={{ readOnly }}
        fullWidth
      />
    </FormControl>
  );
};

export default PasswordInput;
