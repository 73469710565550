import React, { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import PageContainer from '../../components/PageContainer';
import UserRepository from './UserRepository';
import { User } from '../../types';
import UserForm from './UserForm';
import { useSnackbar } from 'notistack';

const CreateUser = () => {
  const repository = new UserRepository();
  const notifications = useSnackbar();
  const history = useHistory();

  const submit = (e: FormEvent<HTMLFormElement>, user: User) => {
    e.preventDefault();
    repository.create(user)
      .then(() => {
        history.push('/users');
        notifications.enqueueSnackbar('User created succesfully!', { variant: 'success' });
      })
      .catch(() => notifications.enqueueSnackbar('Failed to create user!', { variant: 'error' }));
  };

  return (
    <div>
      <PageContainer title="Create user">
        <UserForm submit={submit} />
      </PageContainer>
    </div>
  );
};

export default CreateUser;
