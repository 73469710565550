/* eslint-disable linebreak-style */
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { ApiFilterCriteria, Repository } from '../types';
import FilterColumn, {
  FilterColumnConfig,
  FilterColumnOption,
  FilterColumnOptionCallback,
} from './FilterColumn';
import ConditionalWrapper from './ConditionalWrapper';
import SearchContext from './search/SearchContext';

interface Item {
  id: string;
  [key: string]: any;
}

export interface Column {
  name: string;
  field: string;
  sortable?: boolean;
  filter?:
    | {
    type: 'checkbox' | 'autocomplete';
    options: FilterColumnOption[] | FilterColumnOptionCallback;
    config?: FilterColumnConfig;
  }
    | undefined;
  render?: (item: any) => ReactNode;
}

interface DataTableProps {
  repository: Repository<unknown>;
  columns: Column[];
  searchable?: boolean;
  deletable?: boolean;
  onDeleteFail?: (item: any) => void;
  actions?: (item: any, className: string, loadItems: () => void) => ReactNode;
  onUpdate?: (response: AxiosResponse) => void;
  deleteItemMessage?: (item: any) => string;
  className?: string;
  contained?: boolean;
  defaultOrder?: {
    field: string;
    order: 'asc' | 'desc';
  };
  noResults?: ReactNode;
  itemsPerPage?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  table: {
    borderCollapse: 'initial',
    borderSpacing: `0 ${theme.spacing(1)}px`,
    paddingTop: 0,
  },
  tableContained: {
    paddingTop: 0,
    padding: theme.spacing(2),
  },
  tableContainerNotContained: {
    width: `calc(100% + ${theme.spacing(2)}px)`,
    padding: theme.spacing(2),
    marginLeft: -theme.spacing(2),
  },
  tableBody: {
    position: 'relative',
    height: 48,
  },
  tr: {
    boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.06)',
  },
  th: {
    padding: theme.spacing(1),
    borderBottom: 'none',
    fontWeight: theme.typography.fontWeightBold,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  td: {
    position: 'relative',
    padding: theme.spacing(1),
    background: '#FFF',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filtersContent: {
    padding: theme.spacing(2),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(2),
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: `calc(100% - ${theme.spacing(2)}px)`,
    opacity: 1,
    background: 'rgba(255, 255, 255, 0.5)',
  },
  loaderHidden: {
    opacity: 0,
    zIndex: -1,
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

const DataTable = (props: DataTableProps) => {
  const classes = useStyles();
  const {
    repository,
    columns,
    searchable,
    deletable,
    onDeleteFail,
    actions,
    deleteItemMessage,
    className,
    contained,
    defaultOrder,
    noResults,
  } = props;
  const { query, setQuery } = useContext(SearchContext);
  const [items, setItems] = useState<Array<unknown>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const withSearch = (!setQuery && searchable) || false;
  const withDelete = deletable || false;
  const isContained = contained === undefined ? true : contained;
  let perPage = props.itemsPerPage || 10;

  const [paginator, setPaginator] = useState<{
    totalPages: number | null;
    currentPage: number;
  }>({
    totalPages: null,
    currentPage: 1,
  });

  const { currentPage } = paginator;

  const [filters, setFilters] = useState<ApiFilterCriteria>({
    query: '',
    filters: {},
    order: defaultOrder !== undefined ? [defaultOrder] : undefined,
  });

  const [deleteState, setDeleteState] = useState<{
    showDialog: boolean;
    item: Item | null;
  }>({
    showDialog: false,
    item: null,
  });

  /**
   * Search
   */
  const doSearch = useCallback(
    _.debounce((query: string) => {
      if (query === filters.query) {
        return;
      }

      setPaginator({ totalPages: null, currentPage: 1 });
      setFilters({ ...filters, query });
    }, 500),
    [filters],
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const q = e.target.value;
    doSearch(q);
  };

  /**
   * Data Loading
   */
  const loadItems = useCallback(() => {
    setLoading(true);
    repository
      .findBy(filters, currentPage)
      .then((response) => {
        let totalItems;
        let items;

        if ('data' in response) {
          const { data } = response;

          if ('hydra:member' in data && 'hydra:totalItems' in data) {
            items = data['hydra:member'];
            totalItems = data['hydra:totalItems'];
          } else {
            items = data.items;
            totalItems = data.totalItems;

            if (data.perPage) {
              perPage = data.perPage;
            }
          }
        } else {
          items = response.items;
          totalItems = response.totalItems;

          if (response.perPage) {
            perPage = response.perPage;
          }
        }

        setItems(items);
        setPaginator({
          ...paginator,
          totalPages: Math.ceil(totalItems / perPage),
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [filters, currentPage, repository]);

  useEffect(() => {
    loadItems();
  }, [filters, currentPage, repository]);

  useEffect(() => {
    doSearch(query);
  }, [query]);

  /**
   * Pagination
   */
  const handlePaginate = (e: ChangeEvent<unknown>, page: number) => {
    setPaginator({ ...paginator, currentPage: page });
  };

  /**
   * Deletion
   */
  const handleDelete = (item: any) => {
    setDeleteState({ item, showDialog: true });
  };

  const handleClose = () => {
    setDeleteState({ item: null, showDialog: false });
  };

  const doDelete = () => {
    if (typeof repository.delete !== 'function') {
      return;
    }

    if (deleteState.item !== null) {
      repository
        .delete(deleteState.item.id)
        .then(() => {
          loadItems();
        })
        .catch(() => {
          if (onDeleteFail) {
            onDeleteFail(deleteState.item);
          }
        });
    }

    setDeleteState({ item: null, showDialog: false });
  };

  /**
   * Sorting
   */
  const handleSort = (property: string) => () => {
    let order: 'asc' | 'desc' = 'asc';

    if (filters.order && filters.order.length > 0) {
      order = filters.order[0].order === 'asc' ? 'desc' : 'asc';
    }
    setFilters({ ...filters, order: [{ field: property, order }] });
  };

  /**
   * Filtering
   */
  const handleFilterColumn = (
    column: Column,
    options: FilterColumnOption[],
  ) => {
    const filter = options.map((option) => option.value).join(',');

    if (filter.length === 0) {
      delete filters.filters![column.field];
    } else {
      filters.filters![column.field] = options
        .map((option) => option.value)
        .join(',');
    }

    setFilters({ ...filters });
  };

  return (
    <Box className={className}>
      <Dialog
        open={deleteState.showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteItemMessage && deleteState.item !== null
              ? deleteItemMessage(deleteState.item)
              : 'Weet je zeker dat je dit item wilt verwijderen?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" autoFocus>
            Annuleren
          </Button>
          <Button onClick={doDelete} color="secondary">
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>

      <ConditionalWrapper
        condition={isContained}
        wrapper={(children) => (
          <Paper>{children}</Paper>
        )}
      >
        <>
          <TableContainer
            className={isContained ? '' : classes.tableContainerNotContained}
          >
            {withSearch && (
              <Box p={2} pb={0} className={classes.searchContainer}>
                <FormControl>
                  <Input
                    type="text"
                    onChange={handleSearch}
                    data-testid="search-box"
                    startAdornment={(
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={['fal', 'search']} />
                      </InputAdornment>
                    )}
                  />
                </FormControl>
              </Box>
            )}
            <Table
              className={`${classes.table} ${
                isContained ? classes.tableContained : ''
              }`}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={`cell-header-${column.field}`}
                      className={classes.th}
                    >
                      {column.sortable && (
                        <TableSortLabel
                          active={
                            filters.order
                            && filters.order[0].field === column.field
                          }
                          direction={
                            filters.order ? filters.order[0].order : 'asc'
                          }
                          onClick={handleSort(column.field)}
                          data-testid={`column-sort-label-${column.field}`}
                        >
                          {column.name}
                        </TableSortLabel>
                      )}
                      {column.filter !== undefined && (
                        <FilterColumn
                          name={column.name}
                          type={column.filter.type}
                          options={column.filter.options}
                          config={column.filter.config || {}}
                          onChange={(options: FilterColumnOption[]) => handleFilterColumn(column, options)}
                        />
                      )}
                      {!column.sortable
                      && column.filter === undefined
                      && column.name}
                    </TableCell>
                  ))}
                  {actions && <TableCell className={classes.th} />}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {!loading && items.length === 0 && (
                  <div className={classes.noResults}>
                    {noResults === undefined && 'Geen resultaten gevonden.'}
                    {noResults !== undefined && noResults}
                  </div>
                )}
                <div
                  className={`${classes.loader} ${
                    loading ? '' : classes.loaderHidden
                  }`}
                >
                  <CircularProgress />
                </div>
                {items.map((item) => (
                  <TableRow
                    key={(item as { id: string }).id}
                    data-testid="table-row"
                    className={classes.tr}
                  >
                    {columns.map((column) => {
                      if (!column.render) {
                        return (
                          <TableCell
                            key={`${(item as { id: string }).id}-cell-${
                              column.field
                            }`}
                            className={classes.td}
                          >
                            {(item as any)[column.field]}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell
                          key={`${(item as { id: string }).id}-cell-${
                            column.field
                          }`}
                          className={classes.td}
                        >
                          {column.render(item)}
                        </TableCell>
                      );
                    })}
                    {(actions || withDelete) && (
                      <TableCell
                        key={`${(item as { id: string }).id}-cell-actions`}
                        className={classes.td}
                      >
                        <div className={classes.actions}>
                          {actions
                          && actions(item, classes.iconButton, loadItems)}
                          {withDelete && (
                            <Tooltip title="Verwijderen">
                              <IconButton
                                size="small"
                                onClick={() => handleDelete(item as any)}
                              >
                                <FontAwesomeIcon icon={['fal', 'trash']} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {paginator.totalPages !== null && paginator.totalPages > 1 && (
            <Box p={2}>
              <Pagination
                count={paginator.totalPages}
                page={paginator.currentPage}
                onChange={handlePaginate}
              />
            </Box>
          )}
        </>
      </ConditionalWrapper>
    </Box>
  );
};

export default DataTable;
