import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Paper, TextField, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import UserRepository from './UserRepository';
import { User } from '../../types';
import ApiClient from '../../api/ApiClient';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 450,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

type UserFormProps = {
  user?: User,
  submit: (e: FormEvent<HTMLFormElement>, user: User) => void | null,
};

const UserForm = (props: UserFormProps) => {
  const { user: initialUser, submit } = props;
  const [user, setUser] = useState<User>(initialUser || {
    email: '',
    roles: [],
  });
  const [roles, setRoles] = useState<{[role: string]: string}>({});

  useEffect(() => {
    ApiClient.getRoles().then((res) => setRoles(res.data));
  }, []);

  const classes = useStyles();

  const toggleRole = (role: string) => {
    if (!user) return;
    let { roles } = user;

    if (roles.includes(role)) {
      roles = user.roles.filter((r: string) => r !== role);
    } else {
      roles.push(role);
    }

    setUser({ ...user, roles });
  };

  return (
    <Paper className={classes.container}>
      <form autoComplete="off" onSubmit={(e) => submit(e, user)}>
        <Box mb={3}>
          <TextField
            label="E-mail"
            className={classes.field}
            value={user.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Password"
            type="password"
            className={classes.field}
            value={user.password || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setUser({ ...user, password: e.target.value })}
            fullWidth
          />
        </Box>
        { Object.entries(roles).length > 0 && (
        <Box mb={3}>
          {
                  Object.entries(roles).map((role) => (
                    <FormControlLabel
                      key={role[0]}
                      control={(
                        <Checkbox
                          checked={user.roles.includes(role[0])}
                          onChange={() => toggleRole(role[0])}
                          color="primary"
                        />
                      )}
                      label={role[1]}
                    />
                  ))
                }
        </Box>
        )}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
        >
          Save
        </Button>
      </form>
    </Paper>
  );
};

export default UserForm;
