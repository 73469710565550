import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormLabel, Paper, TextField, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import PageContainer from '../../components/PageContainer';
import PlatformRepository from './PlatformRepository';
import UserAutocomplete from '../users/UserAutocomplete';
import { MinimalUser } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 450,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const CreatePlatform = () => {
  const history = useHistory();
  const notifications = useSnackbar();
  const [state, setState] = useState<{
    name: string,
    url: string,
    privateKey: string,
    owners: MinimalUser[],
    mobileApp: boolean,
  }>({
    name: '',
    url: '',
    privateKey: '',
    owners: [],
    mobileApp: false,
  });
  const classes = useStyles();
  const { name, url, privateKey, owners, mobileApp } = state;

  const submit = (e: FormEvent) => {
    e.preventDefault();
    PlatformRepository.create(name, url, privateKey, owners, mobileApp)
      .then(() => {
        history.push('/platforms');
        notifications.enqueueSnackbar('Created platform succesfully!', { variant: 'success' });
      });
  };

  const handleOwnersChange = (owners: MinimalUser[]) => setState({ ...state, owners });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <PageContainer title="Create platform">
        <Paper className={classes.container}>
          <form autoComplete="off" onSubmit={submit}>
            <Box mb={3}>
              <TextField
                label="Name"
                className={classes.field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, name: e.target.value })}
                fullWidth
                required
              />
              <TextField
                label="URL"
                type="url"
                className={classes.field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, url: e.target.value })}
                fullWidth
                required
              />
              <TextField
                label="Private Key"
                type="password"
                className={classes.field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, privateKey: e.target.value })}
                fullWidth
                autoComplete="off"
                required
              />
              <Box mt={2}>
                <FormLabel>Eigenaren</FormLabel>
                <Box mt={1}>
                  <UserAutocomplete onChange={handleOwnersChange} initialUsers={owners} />
                </Box>
              </Box>
              <Box mt={2}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={mobileApp}
                      onChange={handleCheckboxChange}
                      name="mobileApp"
                    />
                  )}
                  label="Mobiele app"
                />
              </Box>
            </Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
            >
              Save
            </Button>
          </form>
        </Paper>
      </PageContainer>
    </div>
  );
};

export default CreatePlatform;
