import React from 'react';

import UsersOverview from './UsersOverview';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import router, { RouteNode } from '../../config/router';

const users = new RouteNode('Users', '/users', <UsersOverview />);
users.addChild(new RouteNode('Create user', '/create', <CreateUser />));
users.addChild(new RouteNode('Edit user', '/:id/edit', <EditUser />));
router.addRoute(users);
