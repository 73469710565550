import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';

export interface FilterColumnOption {
  value: string;
  label: string;
}

export interface FilterColumnOptionCallback {
  (): Promise<FilterColumnOption[]>;
}

export interface FilterColumnConfig {
  autoCompleteLabel?: string;
  autoCompletePlaceholder?: string;
}

interface FilterColumnProps {
  name: string;
  type: 'checkbox' | 'autocomplete';
  options: FilterColumnOption[] | FilterColumnOptionCallback;
  onChange: (options: FilterColumnOption[]) => void;
  config?: FilterColumnConfig;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  name: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  formGroup: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  autoComplete: {
    width: 250,
    margin: theme.spacing(2),
  },
}));

const FilterColumn = (props: FilterColumnProps) => {
  const { name, type, config, onChange } = props;
  const classes = useStyles();
  const [options, setOptions] = useState<FilterColumnOption[] | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: boolean }>({});
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const openMenu = async (target: HTMLDivElement) => {
    if (options === null && typeof props.options === 'function') {
      setOptions(await props.options());
    }

    setAnchorEl(target);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    openMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const handleAutocompleteChange = (event: any, options: any) => {
    const newFilters: {
      [key: string]: boolean;
    } = {};

    options.forEach((option: FilterColumnOption) => {
      newFilters[option.value] = true;
    });

    setFilters(newFilters);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!options) {
      return;
    }

    onChange(options.filter((option) => filters[option.value] || false));
  }, [filters]);

  useEffect(() => {
    if (typeof props.options !== 'function') {
      setOptions(props.options);
    }
  }, []);

  return (
    <>
      <div className={classes.column} onClick={handleClick}>
        <div className={classes.name}>{name}</div>
        <FontAwesomeIcon icon={['fal', 'chevron-down']} />
      </div>
      {options && (
        <Popover
          id={open ? `filter-column-${name.toLowerCase()}` : undefined}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {type === 'checkbox' && (
            <FormGroup className={classes.formGroup}>
              {options.map((option) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters[option.value] || false}
                      onChange={handleCheckboxChange}
                      name={option.value}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
          )}

          {type === 'autocomplete' && (
            <Autocomplete
              multiple
              id="tags-standard"
              getOptionLabel={(option) => option.label}
              options={options}
              onChange={handleAutocompleteChange}
              noOptionsText="Geen opties beschikbaar."
              value={options.filter((option) => filters[option.value] || false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={config?.autoCompleteLabel || ''}
                  placeholder={config?.autoCompletePlaceholder || ''}
                />
              )}
              className={classes.autoComplete}
            />
          )}
        </Popover>
      )}
    </>
  );
};

export default FilterColumn;
