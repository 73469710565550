import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import PageContainer from '../../components/PageContainer';
import PlatformRepository from './PlatformRepository';
import UserAutocomplete from '../users/UserAutocomplete';
import { MinimalUser, Oauth2Client } from '../../types';
import PasswordInput from '../../components/password/PasswordInput';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const EditPlatform = () => {
  const notifications = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<{
    name: string,
    url: string,
    privateKey: string | null,
    finished: boolean,
    owners: MinimalUser[],
    loaded: boolean,
    mobileApp: boolean,
    client?: Oauth2Client,
  }>({
    name: '',
    url: '',
    privateKey: null,
    finished: false,
    owners: [],
    loaded: false,
    mobileApp: false,
  });

  useEffect(() => {
    PlatformRepository.find(id)
      .then((response) => {
        const platform = response.data;
        setState({
          ...state,
          name: platform.name,
          url: platform.url,
          owners: platform.owners,
          mobileApp: platform.mobileApp,
          client: platform.client,
          loaded: true,
        });
      })
      .catch(() => {
        history.push('/platforms');
      });
  }, []);

  const classes = useStyles();
  const { name, url, privateKey, owners, mobileApp, loaded, client, finished } = state;

  const submit = (e: FormEvent) => {
    e.preventDefault();
    PlatformRepository.update(id, {
      name,
      url,
      owners: owners.map((user) => user.id),
      mobileApp,
      privateKey,
    })
      .then(() => {
        history.push('/platforms');
        notifications.enqueueSnackbar('Platform changed succesfully!', { variant: 'success' });
      })
      .catch(() => {
        notifications.enqueueSnackbar('An error occured while saving the platform!', { variant: 'error' });
      });
  };

  const handleOwnersChange = (owners: MinimalUser[]) => setState({ ...state, owners });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      { finished && <Redirect to="/platforms" /> }
      <PageContainer title="Edit platform">
        { loaded && (
          <Grid container spacing={2} className={classes.container}>
            <Grid item md={6}>
              <Paper>
                <Box p={2}>
                  <form autoComplete="off" onSubmit={submit}>
                    <Box mb={3}>
                      <TextField
                        label="Name"
                        className={classes.field}
                        value={name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, name: e.target.value })}
                        fullWidth
                        required
                      />
                      <TextField
                        label="URL"
                        type="url"
                        className={classes.field}
                        value={url}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, url: e.target.value })}
                        fullWidth
                        required
                      />
                      <TextField
                        label="Private Key"
                        type="password"
                        className={classes.field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setState({ ...state, privateKey: e.target.value })}
                        fullWidth
                        autoComplete="off"
                      />
                      <Box mt={2}>
                        <FormLabel>Eigenaren</FormLabel>
                        <Box mt={1}>
                          <UserAutocomplete onChange={handleOwnersChange} initialUsers={owners} />
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              checked={mobileApp}
                              onChange={handleCheckboxChange}
                              name="mobileApp"
                            />
                      )}
                          label="Mobiele app"
                        />
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
                    >
                      Save
                    </Button>
                  </form>
                </Box>
              </Paper>
            </Grid>
            {client && (
              <Grid item md={6}>
                <Paper>
                  <Box p={2}>
                    <Typography variant="h2">OAuth 2.0 Client</Typography>
                    <Box mt={2}>
                      <TextField
                        label="Client ID"
                        className={classes.field}
                        value={client.identifier}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <PasswordInput
                        id="secret"
                        label="Client secret"
                        className={classes.field}
                        value={client.secret}
                        readOnly
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        ) }
      </PageContainer>
    </div>
  );
};

export default EditPlatform;
