import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  AppBar,
  Button,
  Drawer,
  ListItem,
  Toolbar,
  Typography,
  ListItemIcon,
  ListItemText,
  List,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as Logo } from './assets/logo_white_yunits.svg';
import UserAuthenticator from './api/UserAuthenticator';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    width: 'auto',
    height: 30,
  },
  appBar: {
    zIndex: 1400,
    background: '#02225B',
  },
  menuButton: {
    marginRight: theme.spacing(1),
    fontSize: 23,
  },
  menu: {
    width: 250,
    marginTop: 80,
  },
  listItemIcon: {
    minWidth: 35,
  },
  title: {
    flexGrow: 1,
  },
  titleLink: {
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
  },
}));

function Navigation() {
  const history = useHistory();

  const classes = useStyles();
  const isLoggedIn = useSelector((selector: {user: { isLoggedIn: boolean }}) => selector.user.isLoggedIn);
  const dispatch = useDispatch();

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault();

    new UserAuthenticator(dispatch).logout(() => {
      history.push('/');
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to="/" className={classes.titleLink}>
              <Logo className={classes.logo} />
            </Link>
          </Typography>
          { isLoggedIn && (
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        variant="persistent"
        open
      >
        <List className={classes.menu}>
          <ListItem button component={Link} to="/">
            <ListItemIcon className={classes.listItemIcon}>
              <FontAwesomeIcon icon={['fad', 'browser']} />
            </ListItemIcon>
            <ListItemText primary="Platforms" />
          </ListItem>
          <ListItem button component={Link} to="/users">
            <ListItemIcon className={classes.listItemIcon}>
              <FontAwesomeIcon icon={['fad', 'users']} />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button component={Link} to="/modules">
            <ListItemIcon className={classes.listItemIcon}>
              <FontAwesomeIcon icon={['fad', 'atom']} />
            </ListItemIcon>
            <ListItemText primary="Modules" />
          </ListItem>
          <ListItem button component={Link} to="/time-estimation">
            <ListItemIcon className={classes.listItemIcon}>
              <FontAwesomeIcon icon={['fad', 'hourglass']} />
            </ListItemIcon>
            <ListItemText primary="Time Estimation" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

export default Navigation;
