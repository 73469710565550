import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  IconButton, Theme, Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/styles';
import PageContainer from '../../components/PageContainer';

import PlatformRepository from './PlatformRepository';
import PlatformStatus from './PlatformStatus';
import { Platform } from '../../types';
import DataTable from '../../components/DataTable';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    marginRight: theme.spacing(2),
  },
  mobileApp: {
    '& .fa-primary': {
      fill: theme.palette.primary.main,
    },
    '& .fa-secondary': {
      fill: theme.palette.primary.dark,
    },
  },
}));

const PlatformOverview = () => {
  const classes = useStyles();
  const notifications = useSnackbar();
  const [sync, setSync] = useState<{ syncing: boolean }>({ syncing: false });
  const repository = new PlatformRepository();

  const handleSyncAll = () => {
    setSync({ syncing: true });
    repository.syncAll()
      .then(() => {
        notifications.enqueueSnackbar('Platforms are syncing and will be updated soon!', { variant: 'success' });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Failed to sync platforms!', { variant: 'error' });
      })
      .finally(() => {
        setSync({ syncing: false });
      });
  };

  const handleSync = (id: string, loadItems: () => void) => {
    repository.sync(id)
      .then((response) => {
        if (response.data.syncFailed) {
          notifications.enqueueSnackbar('Failed to sync platform!', { variant: 'error' });
        } else {
          notifications.enqueueSnackbar('Platform synced succesfully!', { variant: 'success' });
        }
      })
      .catch(() => {
        notifications.enqueueSnackbar('Failed to sync platform!', { variant: 'error' });
      })
      .finally(() => {
        loadItems();
      });
  };

  const Actions = () => (
    <Box>
      <Button
        color="primary"
        startIcon={<FontAwesomeIcon icon={['fal', 'sync']} spin={sync.syncing} />}
        className={classes.actionButton}
        onClick={handleSyncAll}
      >
        Sync all
      </Button>
      <Link to="/platforms/create">
        <Button color="primary" variant="contained" startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}>
          Add platform
        </Button>
      </Link>
    </Box>
  );

  const itemActions = (item: Platform, className: string, loadItems: () => void) => (
    <div>
      <IconButton size="small" onClick={() => handleSync(item.id, loadItems)} className={className}>
        <FontAwesomeIcon icon={['fal', 'sync']} />
      </IconButton>
      <IconButton size="small" component={Link} to={`/platforms/${item.id}/edit`} className={className}>
        <FontAwesomeIcon icon={['fal', 'edit']} />
      </IconButton>
    </div>
  );

  const columns = [
    {
      name: 'Name',
      field: 'name',
      sortable: true,
      render: (item: Platform) => (
        <Box display="flex">
          <Link to={`/platforms/${item.id}`}>
            {item.name}
          </Link>
          {item.mobileApp && (
            <Box ml={1}>
              <Tooltip title="Heeft mobiele app">
                <span>
                  <FontAwesomeIcon icon={['fad', 'mobile']} className={classes.mobileApp} />
                </span>
              </Tooltip>
            </Box>
          )}
        </Box>
      ),
    },
    {
      name: 'URL',
      field: 'url',
      sortable: false,
      render: (item: Platform) => <a href={item.url} rel="noopener noreferrer" target="_blank">{item.url}</a>,
    },
    {
      name: 'Last update',
      field: 'lastCommitDate',
      sortable: true,
      render: (item: Platform) => (
        <div>
          <PlatformStatus platform={item} />
          { !item.syncFailed && (item.lastCommitDate === undefined ? 'Unknown' : moment(item.lastCommitDate).fromNow()) }
          { item.syncFailed && 'Error'}
        </div>
      ),
    },
    {
      name: 'Branch',
      field: 'branch',
    },
    {
      name: 'Eigenaren',
      field: 'owners',
      render: (item: Platform) => (
        <div>
          {item.owners.length === 0 && '-'}
          {item.owners.map((o) => o.email).join(', ')}
        </div>
      ),
    },
  ];

  return (
    <PageContainer title="Platforms" actions={<Actions />}>
      <Box mt={3}>
        <DataTable
          repository={repository}
          columns={columns}
          actions={itemActions}
          itemsPerPage={1000}
          deletable
          searchable
        />
      </Box>
    </PageContainer>
  );
};

export default PlatformOverview;
